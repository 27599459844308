const devConfig = {
	serverURL: process.env.SERVER_URL ?? "http://localhost:1337/1",
	appId: process.env.APP_ID ?? "desdapi",
	authURL: process.env.AUTH_URL ?? "http://localhost:1337/devAuth",
};

const productionConfig = {
	serverURL: "https://pg-app-g36mbvirrcr4jqzwa3y6e1eyuah3aj.scalabl.cloud/1",
	javaScriptKey: "i0AVlqbKBnE0phWPD1MiEcKKUhYBe5MQeQKizMTM",
	appId: "Df64UIiwB6ix3y9rp10AQf6LMee4hQMT7sQYViBY",
	authURL: "https://desda.org/parse.php",
};

const config =
	process.env.NODE_ENV === "production" ||
	process.env.USE_PRODUCTION_SERVER_IN_DEVELOPMENT
		? productionConfig
		: devConfig;

export default config;
